<template>
  <Layout :tituloPagina="'Sistema | Ajustes | Pasarelas pago | '+pasarela.nombre+ ' | Edición' ">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Editar pasarela de pago {{ pasarela.id != null ? '('+pasarela.nombre+')': '' }}
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="guardar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      Guardar
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      Guardar
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="font-size-13">Pasarela Activa</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="configuracion.activa"
                    @change="
                      configuracion.activa = !configuracion.activa,
                      mensajePasarelaActiva()
                    "
                    class="form-check-input"
                    type="checkbox"
                    id="activarPasarela"
                  />
                  <label class="form-check-label" for="activarPasarela"></label>
                </div>
              </div>
              <div class="col-md-6">
                <label class="font-size-13">
                  Modo prueba
                </label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="configuracion.sandbox"
                    @change="
                      configuracion.sandbox = !configuracion.sandbox,
                      mensajeModoPruebas()
                    "
                    class="form-check-input"
                    type="checkbox"
                    id="sandbox"
                  />
                  <label class="form-check-label" for="sandbox"></label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>
                  {{ tituloIdClient() }}
                  <span
                    class="badge"
                    :class="{
                      'bg-success': !configuracion.sandbox, 
                      'bg-warning': configuracion.sandbox
                    }"
                  >
                    {{configuracion.sandbox ? 'Prueba': 'Producción'}}
                  </span>
                </label>
                <input
                  class="form-control"
                  ref="id_client"
                  :placeholder="tituloIdClient()"
                  v-show="!configuracion.sandbox"
                  v-model="configuracion.id_cliente"
                />
                <input
                  class="form-control"
                  ref="sandbox_id_cliente"
                  :placeholder="tituloIdClient()+' (Prueba)'"
                  v-show="configuracion.sandbox"
                  v-model="configuracion.sandbox_id_cliente"
                />
              </div>
              <div class="col-md-6">
                <label>
                  {{ tituloSecret() }}
                  <span
                    class="badge"
                    :class="{
                      'bg-success': !configuracion.sandbox, 
                      'bg-warning': configuracion.sandbox
                    }"
                  >
                    {{configuracion.sandbox ? 'Prueba': 'Producción'}}
                  </span>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    name="clave"
                    :placeholder="mostrar_secret ? 'Password' : '*************'"
                    v-if="!configuracion.sandbox"
                    :type="mostrar_secret ? 'text' : 'password'"
                    v-model="configuracion.secret_cliente"
                  />
                  <input
                    class="form-control"
                    name="clave"
                    :placeholder="mostrar_secret ? 'Password (Prueba)' : '************* (Prueba)'"
                    v-if="configuracion.sandbox"
                    :type="mostrar_secret ? 'text' : 'password'"
                    v-model="configuracion.sandbox_secret_cliente"
                  />
                  <button
                    class="btn btn-light"
                    @click="mostrar_secret = !mostrar_secret"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-eye': mostrar_secret,
                        'fa-eye-slash': !mostrar_secret
                      }"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-6">
                <label>Moneda</label>
                <select class="form-select" v-model="configuracion.id_moneda">
                  <option :value="null">Seleccionar</option>
                  <option 
                    :style="!moneda.soportada ? 'color: red;': ''"
                    v-for="moneda in monedas_soportadas"
                    :value="moneda.id"
                    :key="'moneda-'+moneda.id"
                  >
                    ({{moneda.codigo}}) - {{moneda.nombre}} {{!moneda.soportada ? '** Moneda no soportada **': ''}}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label class="font-size-13">Cobrar comisión</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="configuracion.comision"
                    @change="configuracion.comision = !configuracion.comision"
                    class="form-check-input"
                    type="checkbox"
                    id="comision"
                  />
                  <label class="form-check-label" for="comision"></label>
                </div>
              </div>
            </div>
            <br>
            <div class="row" v-show="configuracion.comision">
              <div class="col-md-6">
                <label for="">Porcentaje de comisión</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    ref="porcentaje_comision"
                    placeholder="5.40"
                    min="0"
                    step="0.25"
                    max="100"
                    type="number"
                    onkeypress="if(event.keyCode < 46 || event.keyCode > 57 || event.keyCode == 47) event.returnValue = false;"
                    v-model="configuracion.porcentaje_comision"
                  />
                  <span class="input-group-text">%</span>
                </div>
              </div>
              <div class="col-md-6">
                <label>Adicional a la comisión</label>
                <div class="input-group">
                  <span class="input-group-text">
                    {{moneda_seleccionada ? moneda_seleccionada.simbolo : ''}}
                  </span>
                  <input
                    class="form-control"
                    ref="adicional_comision"
                    placeholder="0.30"
                    min="0"
                    max="100"
                    type="number"
                    onkeypress="if(event.keyCode < 46 || event.keyCode > 57 || event.keyCode == 47) event.returnValue = false;"
                    v-model="configuracion.adicional_comision"
                  />
                  <span class="input-group-text">
                    {{moneda_seleccionada ? moneda_seleccionada.codigo : ''}}
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-show="configuracion.comision && pasarela.id == 2">
              <div class="col-md-6">
                <label>Impuesto comisión</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    ref="impuesto_comision"
                    placeholder="0.30"
                    min="0"
                    max="100"
                    step="0.25"
                    type="number"
                    onkeypress="if(event.keyCode < 46 || event.keyCode > 57 || event.keyCode == 47) event.returnValue = false;"
                    v-model="configuracion.porcentaje_impuesto_comision"
                  />
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <hr style="margin-top: 1rem; margin-bottom: 1rem;">
            <h6>
              Pasarela de pagos
              (<a href="https://miserviciodeinternet.com" target="_blank">https://miserviciodeinternet.com</a>)
            </h6>
            <p>
              Para activar correctamente el <strong>IPN</strong> de la pasarela de pago <strong>{{ pasarela.id != null ? '('+pasarela.nombre+')': '' }}</strong>.
            </p>
            <p>
              Dar de alta la siguiente ruta en la seccion de <strong>IPN</strong> de su cuenta principal.
            </p>
            <div class="input-group">
              <span class="form-control text-primary" id="nombre_ruta">
                {{ pasarela.nombre_ruta_ipn }}
              </span>
              <button class="btn btn-primary" @click="copiar" title="Copiar la ruta para el IPN">
                <i class="mdi mdi-content-copy"></i>
                Copiar
              </button>
            </div>
            
          </div>
        </div>
        <div class="text-right pb-4">
          <div class="btn-group">
            <button class="btn btn-secondary"
              @click="$router.go(-1)">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button class="btn btn-success" @click="guardar()" :disabled="bandera_spinner">
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import PasarelaPagoSrv from '@/services/PasarelaPagoSrv'
import MonedaSoportadaPasarelaPagoSrv from '@/services/MonedaSoportadaPasarelaPagoSrv.js'

export default {
  name: 'EdicionPasarela',
  components: { Layout },
  data: function() {
    return {
      idPasarela: null,
      pasarela: {
        id: null,
        nombre: '',
        default_id_moneda: 4,
        default_porcentaje_comision: 5,
        default_adicional_comision: 5,
        default_porcentaje_impuesto_comision: 5,
        nombre_ruta_ipn: null,
      },
      configuracion: {
        id_pasarela: null,
        activa: 0,
        id_cliente: null,
        secret_cliente: null,
        id_moneda: null,
        comision: 0,
        porcentaje_comision: 0,
        adicional_comision: 0,
        porcentaje_impuesto_comision: 0,
        sandbox: 0,
        sandbox_id_cliente: null,
        sandbox_secret_cliente: null
      },
      monedas_soportadas: [],
      mostrar_secret: false,
      bandera_spinner: false
    }
  },
  computed: {
    moneda_seleccionada() {
      let moneda = this.monedas_soportadas.find(mnd => {
        return mnd.id == this.configuracion.id_moneda
      })

      return moneda
    }
  },
  created: function() {
    var self = this 

    self.idPasarela = this.$route.params.id

    self.cargarPasarela()
  },
  methods: {
    cargarPasarela: function() {
      var self = this

      PasarelaPagoSrv.pasarelaJSON(self.idPasarela).then(response => {
        self.pasarela = response.data
        self.cargarMonedasSoportadas(function() {
          self.cargarConfiguracionPasarela()
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la pasarela'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarConfiguracionDefault:function() {
      var self = this

      self.configuracion.id_moneda = self.pasarela.default_id_moneda
      self.configuracion.porcentaje_comision = self.pasarela.default_porcentaje_comision
      self.configuracion.adicional_comision = self.pasarela.default_adicional_comision
      self.configuracion.porcentaje_impuesto_comision = self.pasarela.default_porcentaje_impuesto_comision
    },
    cargarConfiguracionPasarela: function() {
      var self = this

      PasarelaPagoSrv.configuracionJSON(this.idPasarela).then(response => {
        self.configuracion = response.data

        let index = this.monedas_soportadas.findIndex(m => {
          return m.id == self.configuracion.id_moneda
        })

        if(index == -1 && self.configuracion.id_moneda != null) self.cargarMonedaFaltante(self.configuracion.id_moneda)
        
      }).catch(error => {
        let mensaje, code
        try {
          mensaje = error.response.data.message
          code = error.response.data.codigo
        } catch (e) {
          mensaje = 'No se pudo cargar la confirguracion de la pasarela de pagos'
        }
        if(code == 404) {
          self.cargarConfiguracionDefault()
        } else {
          iu.msg.error(mensaje)
          console.log(error)
        }
      })
    },
    cargarMonedaFaltante:function(idMoneda) {
      var self = this

      MonedaSoportadaPasarelaPagoSrv.monedaJSON(idMoneda).then(response => {
        let moneda = response.data
        moneda.soportada = false

        self.monedas_soportadas.push(moneda)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las moneda faltante'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarMonedasSoportadas: function(callback) {
      var self = this,
        params =  {
          id_pasarela: self.idPasarela
        }

      MonedaSoportadaPasarelaPagoSrv.monedasJSON(params).then(response => {
        self.monedas_soportadas = response.data

        self.monedas_soportadas.forEach(m => {
          m.soportada = true
        })
        
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las monedas soportadas para la pasarela de pagos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrar: function() {
      this.$router.go(-1)
    },
    copiar() {
      var self = this
      var aux = document.createElement("input");
      aux.setAttribute("value", self.pasarela.nombre_ruta_ipn);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);

      iu.msg.success('La ruta para el IPN de la pasarela de pagos '+self.pasarela.nombre+', sea copiado correctamente.')
    },
    guardar: function() {
      var self = this 

      if(self.configuracion.comision == true && (self.configuracion.porcentaje_comision === '' || self.configuracion.porcentaje_comision === null)){
        iu.msg.warning('No puede ir vacío el campo porcentaje de comisión')
        self.$refs.porcentaje_comision.select()
        return
      }

      if(self.configuracion.comision == true && (self.configuracion.adicional_comision === '' || self.configuracion.adicional_comision === null)){
        iu.msg.warning('No puede ir vacío el campo adicional a la comisión')
        self.$refs.adicional_comision.select()
        return
      }

      if(self.pasarela.id == 2 && self.configuracion.comision == true && (self.configuracion.porcentaje_impuesto_comision === '' || self.configuracion.porcentaje_impuesto_comision === null)){
        iu.msg.warning('No puede ir vacío el porcentaje de impuesto a la comisión')
        self.$refs.impuesto_comision.select()
        return
      }

      let config = {
        id_pasarela: self.pasarela.id,
        activa: self.configuracion.activa,
        id_cliente: self.configuracion.id_cliente,
        secret_cliente: self.configuracion.secret_cliente,
        id_moneda: self.configuracion.id_moneda,
        comision: self.configuracion.comision,
        porcentaje_comision: self.configuracion.porcentaje_comision,
        adicional_comision: self.configuracion.adicional_comision,
        porcentaje_impuesto_comision: self.configuracion.porcentaje_impuesto_comision,
        sandbox: self.configuracion.sandbox,
        sandbox_id_cliente: self.configuracion.sandbox_id_cliente,
        sandbox_secret_cliente: self.configuracion.sandbox_secret_cliente
      }

      PasarelaPagoSrv.guardarConfiguracion(self.pasarela.id, config).then(response => {
        iu.msg.success("Se actualizaron correctamente los datos de la pasarela de pagos")
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las monedas soportadas para la pasarela de pagos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    mensajeModoPruebas(){
      var self = this 

      if(self.configuracion.sandbox)
        iu.msg.info("En modo prueba, los pagos y operaciones no tienen costo son ficticios. ", {
          position: `top-right`,
          duration: 4000,
          dismissible: true,
          pauseOnHover: true,
          useDefaultCss: true
        })
      else
        iu.msg.info("Advertencia a partir de este momento, los pagos y operaciones serán aplicados.",{
          position: `top-right`,
          duration: 4000,
          dismissible: true,
          pauseOnHover: true,
          useDefaultCss: true
        })
    },
    mensajePasarelaActiva(){
      var self = this 

      if(self.configuracion.activa)
        iu.msg.info("La pasarela "+self.pasarela.nombre+ ", se encuentra activa en mi servicio de internet.", {
          position: `top-right`,
          duration: 4000,
          dismissible: true,
          pauseOnHover: true,
          useDefaultCss: true
        })
      else
        iu.msg.info("La pasarela "+self.pasarela.nombre+ ", se encuentra inactiva en mi servicio de internet.", {
          position: `top-right`,
          duration: 4000,
          dismissible: true,
          pauseOnHover: true,
          useDefaultCss: true
        })
    },
    tituloIdClient() {
      var self = this 

      switch(self.pasarela.nombre) {
        case 'PayPal':
          return 'Client ID'
        break;
        case 'Mercado Pago':
          return 'Public Key'
        break;
        default:
          return 'Client ID'
        break;
      }
    },
    tituloSecret() {
      var self = this 

      switch(self.pasarela.nombre) {
        case 'PayPal':
          return 'Secret'
        break;
        case 'Mercado Pago':
          return 'Access Token'
        break;
        default:
          return 'Secret'
        break;
      }
    }
  }
}
</script>